import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { ConfigService } from "../../core/services/config.service";
import { WebSocketService } from "../../core/services/web-socket.service";
import { RouterService } from "../../core/services/router.service";

@Injectable({
    providedIn: 'root'
})
export class AgentGuard implements CanActivate {

    constructor(
        private router: RouterService,
        private config: ConfigService,
        private socket: WebSocketService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
        // const agentId = route.paramMap.get('agent_id');
        const agentId = route.url[0].path;

        const id = this.config.getAgentId();
        console.log(id);

        // If reload lost-network page
        if (this.config.getAgentId() === agentId) {
            return true;
        }

        console.log(agentId)

        if (!agentId) {
            // this.router.goToNotFound();
            return false;
        }

        this.config.setAgentId(agentId);

        return this.config.fetchConfiguration().then(config => {
            this.config.setAgentConfig(config.body);
            console.log('config set');
            return this.socket.initConnection().then(() => {
                return true;
            }).catch(e => {
                this.router.goToNotFound();
                console.log('error while setting configuration' + e.toString());
                return false;
            });
        }).catch(e => {
            this.router.goToNotFound();
            console.log('error while setting configuration' + e.toString());
            return false;
        });
    }
}
