import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';

import { GuardsCheckEnd, GuardsCheckStart, NavigationCancel, Router } from "@angular/router";
import { from, map, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, OnDestroy {
    loading = false;
    metaData: any;

    @HostListener('window:resize')
    onResize(): void {
        this.setVh();
    }

    constructor(private router: Router, private http: HttpClient) {
        // Start and end loading while canActive is running
        this.router.events.subscribe(event => {
            if (event instanceof GuardsCheckStart) {
                this.loading = true;
                console.log("ResolveStart")
            }
            if (event instanceof GuardsCheckEnd || event instanceof NavigationCancel) {
                this.loading = false;
                console.log("ResolveEnd")
            }
        });
    }

    /*async fetchOpenGraph(url: string) {
        try {
            // Fetch the HTML content of the URL
            const response = await fetch(url);
            const html = await response.text();
            // Parse the HTML using DOMParser
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, "text/html");
            // Extract all meta tags with property attribute
            const metaTags = doc.querySelectorAll('meta[property^="og:"]');
            const ogData: { [key: string]: string | null } = {};
            // Loop through each meta tag and add to the ogData object
            metaTags.forEach((tag) => {
                const property = tag.getAttribute('property');
                const content = tag.getAttribute('content');
                if (property) {
                    ogData[property] = content;
                }
            });
            return ogData;
        } catch (error) {
            console.error("Error fetching or parsing Open Graph data:", error);
            return null;
        }
    }*/

    /*getMetaData(url: string) {
        //const response = await fetch(url, { mode: 'no-cors' });

        return this.http.get(url, { responseType: 'text' }).pipe(
            map(html => {
                const parser = new DOMParser();
                const doc = parser.parseFromString(html, 'text/html');

                const title = doc.querySelector('meta[property="og:title"]')?.getAttribute('content') || '';
                const description = doc.querySelector('meta[property="og:description"]')?.getAttribute('content') || '';
                const image = doc.querySelector('meta[property="og:image"]')?.getAttribute('content') || '';

                return { title, description, image };
            })
        );
    }*/

    /*getMetaData(url: string): Observable<any> {
        return from(
            fetch(url,{ mode: 'no-cors' })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.text();
            })
            .then(html => {
                const parser = new DOMParser();
                const doc = parser.parseFromString(html, 'text/html');

                const title = doc.querySelector('meta[property="og:title"]')?.getAttribute('content') || '';
                const description = doc.querySelector('meta[property="og:description"]')?.getAttribute('content') || '';
                const image = doc.querySelector('meta[property="og:image"]')?.getAttribute('content') || '';

                return { title, description, image };
            })
        );
    }

    fetchMetaData() {
        const url = 'https://www.milica.com/';

        //const url = '/api/'; // Zamijenite ovim URL-om
        this.getMetaData(url).subscribe(data => {
            this.metaData = data;
            console.log(this.metaData);
        });
    }*/

    ngOnInit(): void {
        this.setVh();

        //this.fetchMetaData();

        // Usage example
        //this.fetchOpenGraph('https://example.com').then((ogData) => {  console.log(ogData);});
    }

    private setVh(): void {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    ngOnDestroy(): void {
        window.removeEventListener('resize', this.onResize);
    }
}
